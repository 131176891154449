/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.modalCss {
  --width: 100vw;
  --height: 100%;
  --background: transparent !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="month"]::-webkit-calendar-picker-indicator {
  display: none;
}

// Action Button
.editBtn {
  position: relative;
  padding: 7px 10px;
  border-radius: 5px;
  // border: 1px solid rgb(61, 106, 255);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  background: transparent;
  overflow: hidden;
  cursor: pointer;
  width: fit-content;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.20);
  // letter-spacing: 0.5px;
}

.editBtn:hover {
  background: #6c0d80;
  box-shadow: 0 3px 5px rgba(0, 142, 236, 0.2);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  color: white;
}

.editBtn:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

.editBtn::before {
  content: '';
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

.editBtn:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}

.fullpagemodal {
  // .modal-wrapper {

  // display: block;
  --width: calc(100vw - 250px);
  --height: calc(100vh - 100px);

  // }
}

.dashboardModal {
  --border-radius: 30px;
  --width: calc(85vh);
  --height: calc(85vh);
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

ion-popover .popover-viewport {
  display: contents;
}

.j-style-btn {
  --background: #6e63e5;
  --background-activated: #6e63e5;
  --background-focused: #6e63e5;
  --background-hover: #6e63e5;
  --background-focused-opacity: 0.7;
  --background-hover-opacity: 0.7;
}

input[type="radio"]:disabled {
  -webkit-appearance: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  padding: 0px;
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: white;
  border-radius: 50%;
}

input[type="radio"]:checked {
  background-color: #6e63e5;
}

// input[type=radio]#Green { 
//   accent-color: rgb(0, 255, 0); 
// } 

// input[type=radio]#auto { 
//   accent-color: auto; 
// } 

// input[type=radio]#Red { 
//   accent-color: #FF0000; 
// } 